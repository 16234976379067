import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import Modal from '@mui/material/Modal/'
import {Backdrop, Box, Fade} from "@mui/material"

import './Modal.scss'

export default function AppModal(props) {
    // ** states
    const [open, setOpen] = useState(false)
    const {t} = useTranslation()

    // ** Close modal
    const handleClose = () => props.onClose()

    // Modal styles
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 560,
        maxWidth: "calc(100% - 30px)",
        maxHeight: "90vh",
        overflow: "auto",
        borderRadius: 1,
        boxShadow: 24,
        bgcolor: props['type'] === 'video' ? 'transparent' : 'background.paper',
    }

    // Render modal content
    const renderModalContent = () => {
        switch (props.type) {
            case "video":
                return (
                    <iframe
                        width="100%"
                        height="315"
                        src={"https://www.youtube.com/embed/" + props.video}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen/>
                )
            case "voice":
                return (
                    <div className='success__modal'>
                        <h4 className='text-center my-4'>
                            {t("voice1")}
                        </h4>
                        <h6 className='text-center my-4'>
                            {t("voice2")} <br/>
                            {t("voice3")}
                        </h6>
                        <button
                            onClick={() => {
                                props.handleCloseModal()
                            }}
                            className='button button__purple ms-auto'>
                            {t("close")}
                        </button>
                    </div>
                )
            case "successModal":
                return (
                    <div className='success__modal'>
                        <h4 className='text-center my-4'>
                            {t("successForm")}
                        </h4>
                        <h6 className='text-center my-4'>
                            {t("successFormDescription")}
                        </h6>
                        <button
                            onClick={() => {
                                props.handleCloseModal()
                            }}
                            className='button button__purple ms-auto'>
                            {t("close")}
                        </button>
                    </div>
                )
            default:
                break
        }
    }

    // Effects
    useEffect(() => {
        setOpen(props.open)
    }, [props])

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 300,
            }}
        >
            <Fade in={open} className="application">
                <Box sx={style}>
                    {renderModalContent()}
                </Box>
            </Fade>
        </Modal>
    )
}