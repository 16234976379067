import {Col, Container, Row} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import img from '../../../resources/img/404.webp'

export default function Page404() {
    // ** States
    const {t} = useTranslation()

    return (
        <section className='py pt-5 mt-5'>
            <Container>
                <Row className="align-items-center flex-md-row flex-column-reverse justify-content-between">
                    <Col md={6}>
                        <img className="w-100 mt-3" src={img} alt="empty"/>
                    </Col>
                    <Col md={5} className='d-flex flex-column align-items-center text-center'>
                        <h1>{t("404title")}</h1>
                        <p>{t("404text")}</p>
                        <button className='button button__purple w-auto px-4'>
                            <Link to="/">
                                {t("sendbtn")}
                            </Link>
                        </button>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}