import {useHttp} from "../hooks/http.hook";

export default function StatisticsService() {
    const {error, loading, request} = useHttp()

    const getStatistic = async () => {
        return await request(`${process.env.REACT_APP_API}site/statistics`, "GET")
    }

    return {error, loading, getStatistic}
}