import {useHttp} from "../hooks/http.hook"

export default function HelpService() {
    const {error, loading, request} = useHttp()

    const getHelp = async () => {
        return await request(`${process.env.REACT_APP_API}site/help`, "GET")
    }

    return {error, loading, getHelp}
}