import {useContext, useEffect, useState} from 'react'
import VideoService from "../../services/VideoService"
import {useTranslation} from 'react-i18next'
import {ThemeContext} from '../../context/theme/themeContext'
import {LanguageContext} from "../../context/language/languageContext"

import {Col, Container, Row} from 'react-bootstrap'
import {BreadCrumb} from '../../components/BreadCrumb/BreadCrumb'
import AppModal from '../../components/Modal/Modal'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'

import './Videos.scss'

export default function Videos() {
    // ** States
    const [data, setData] = useState(null)
    const [open, setOpen] = useState(false)
    const [video, setVideo] = useState(null)
    const [visible, setVisible] = useState(6)
    const {t} = useTranslation()

    // ** Context
    const [language] = useContext(LanguageContext)
    const [, , , , hiddenImage, , changeVisibilityImage] = useContext(ThemeContext)

    // ** Services
    const {error, getVideo} = VideoService()

    // ** Breadcrumb
    const breadCrumb = [
        {
            title: t("resources"),
            link: null,
        },
        {
            title: t("video"),
            link: null,
        },
    ]

    // Modal toggle
    const toggleOpen = (video) => {
        setVideo(video)
        setOpen(!open)
    }

    // Show more data
    const showMore = () => {
        setVisible(visible + 3)
    }

    // ** Effects
    useEffect(() => {
        getVideo().then(res => {
            setData(res)
            changeVisibilityImage()
        })
    }, [])

    useEffect(() => {
        if (hiddenImage) {
            setTimeout(() => changeVisibilityImage(), 1)
        }
    }, [visible])

    return (
        <section className="video page py mt-3">
            {!error && data && (
                <Container>
                    <BreadCrumb data={breadCrumb}/>

                    <h2 className='section__title' data-aos='fade-right' data-aos-delay='150'>
                        {t('videoMaterials')}
                    </h2>
                    <Row>
                        {data.slice(0, visible).map((item, i) => (
                            <Col
                                key={item['id']}
                                lg={4}
                                sm={6}
                                className='mb-4'
                                data-aos='fade-up'
                                data-aos-delay={i < 6 ? (0 + ((i + 1) * 100)) : 0}
                            >
                                <div className='video__card'>
                                    <div onClick={() => toggleOpen(item['link'])} className='video__overlay'>
                                        <div className='video__overlay-img'>
                                            <PlayCircleOutlineIcon/>
                                        </div>
                                        <img
                                            src={item['image']}
                                            width="100%"
                                            height="400"
                                            alt={item['title'][language]}/>
                                    </div>
                                    <div className='p-3'>
                                        <h4>{item['title'][language]}</h4>
                                        <p>{item['subtitle'][language]}</p>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                    {data.length <= visible ? "" :
                        <button
                            className='button button__purple d-flex justify-content-center align-items-center mx-auto '
                            onClick={showMore}>Показать еще</button>
                    }
                </Container>
            )}

            <AppModal
                type="video"
                video={video}
                open={open}
                onClose={() => toggleOpen(null)}
            />
        </section>
    )
}