import {useContext, useEffect, useState} from "react"
import {LanguageContext} from "../../../context/language/languageContext"
import OrganizationService from "../../../services/OrganizationService"
import {useTranslation} from "react-i18next"

import PartnersSwiper from "../../../components/PartnersSwiper/PartnersSwiper"
import {Col, Container, Row} from "react-bootstrap"
import Social from "../../../components/Social/Social"
import InformationSupportSwiper from "../../../components/InformationSupportSwiper/InformationSupportSwiper";

import "./IntroPartners.scss"

function IntroPartners() {
    // ** States
    const [data, setData] = useState(null)
    const {t} = useTranslation()

    // ** Context
    const [language] = useContext(LanguageContext)

    // ** Services
    const {getOrganization} = OrganizationService()

    // Effects
    useEffect(() => {
        getOrganization().then(res => {
            setData(res)
        })
    }, [language]);

    return (
        <section className="intropartners page mb-3 overflow-hidden">
            {data && (
                <Container>
                    <Row className='mb-4'>
                        <Col md={5} className="d-flex flex-column justify-content-center">
                            <div className="d-flex flex-column-reverse">
                                {data.map((item, i) => (
                                    <a
                                        key={i}
                                        href={item.link}
                                        className="intropartners__card"
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <img
                                            src={item.image}
                                            width="85"
                                            height="85"
                                            alt={item['title'][language]}
                                            className="intropartners__logo"
                                        />
                                        <span className="intropartners__name">
                                        {item['title'][language]}
                                    </span>
                                    </a>
                                ))}
                            </div>
                            <Social/>
                        </Col>
                        <Col md={7} className="mt-5 mt-md-0">
                            <PartnersSwiper smallPerView={2} mediumPerView={3} largePerView={4}/>
                            <InformationSupportSwiper smallPerView={2} mediumPerView={2} largePerView={3}/>
                        </Col>
                    </Row>
                    <p>{t('warning')}</p>
                </Container>
            )}
        </section>
    )
}

export default IntroPartners