import React, {useContext, useEffect, useState} from 'react'
import {LanguageContext} from '../../../context/language/languageContext'
import {useTranslation} from 'react-i18next'
import MainPageService from '../../../services/MainPageService'
import SettingsService from "../../../services/SettingsService";

import {Col, Container, Row} from 'react-bootstrap'
import AppForm from '../../../components/AppForm/AppForm'

import lenta from "../../../resources/img/introabout/lenta2.png"

import './IntroMap.scss'
import {ThemeContext} from "../../../context/theme/themeContext";

function IntroMap() {
    // ** States
    const [data, setData] = useState(null)
    const [map, setMap] = useState(null)
    const {t} = useTranslation()

    // ** Services
    const {getBlock} = MainPageService()
    const {getSettings} = SettingsService()

    // ** Context
    const [language] = useContext(LanguageContext)
    const [, , , , hiddenImage, , changeVisibilityImage] = useContext(ThemeContext)

    // Unblock map
    const unBlockMap = (e) => {
        e.target.addEventListener("click", () => {
            e.target.classList.add("show")
        })
    }
    const blockMap = () => {
        document.querySelector(".intromap__map-block").classList.remove("show")
    }

    // ** Effects
    useEffect(() => {
        getBlock().then(res => {
            setData(res['mainBlocks'])
        })
        getSettings().then(res => setMap(res['maps']))
    }, [language])

    useEffect(() => {
        if (hiddenImage) {
            changeVisibilityImage()
        }
    }, [data])


    return (
        <>
            {data && map && (
                <section className='intromap page'>
                    <Container>
                        <h2 className='section__title' data-aos='fade-right'>{t("mapMarks")}</h2>
                        <div
                            className='intromap__map'
                            onMouseEnter={unBlockMap}
                            onMouseLeave={blockMap}
                            data-aos='fade-up'
                        >
                            <div
                                className="intromap__map-block"
                            />
                            <div dangerouslySetInnerHTML={{__html: map}}/>
                        </div>
                        <p className='intromap__call' data-aos='fade-up'>
                            {t("mapSubtitle1") + ' '}
                            <a
                                className='text-primary'
                                href='https://t.me/CallUmida_bot'
                                target='_blank'
                                rel='noreferrer'
                            >
                                @CallUmida_bot
                            </a>
                            {' ' + t("mapSubtitle2")}
                        </p>
                    </Container>
                    <img className='intromap__lenta' src={lenta} alt="line"/>
                    <Container>
                        <Row className="align-items-center justify-content-between mt-3">
                            <Col md={7} className="introabout__content">
                                <h2 className='section__title' data-aos='fade-right'>
                                    {data[0].title[language]}
                                </h2>
                                <div dangerouslySetInnerHTML={{__html: data[0].description[language]}} data-aos='fade-up'/>
                            </Col>
                            <Col md={5} className="d-flex justify-content-center">
                                <img className='introabout__img'
                                     src={data[0].image}
                                     width="400"
                                     height="400"
                                     alt={data[0].title[language]}
                                     data-aos='fade-left'
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
            )}
            <AppForm id="callback"/>
        </>
    )
}

export default IntroMap