import {useHttp} from "../hooks/http.hook";

export default function SettingsService() {
    const {error, loading, request} = useHttp()

    const getSettings = async () => {
        return await request(`${process.env.REACT_APP_API}site/settings`, "GET")
    }

    return {error, loading, getSettings}
}