import {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import StatisticsService from "../../services/StatisticsService"
import {ThemeContext} from '../../context/theme/themeContext'
import {LanguageContext} from "../../context/language/languageContext"

import {Col, Container, Row} from 'react-bootstrap'
import {BreadCrumb} from '../../components/BreadCrumb/BreadCrumb'

import lenta from '../../resources/img/introabout/lenta2.png'
import './Statistics.scss'

export default function Statistics() {
    // ** States
    const [data, setData] = useState(null)
    const [description, setDescription] = useState(null)
    const {t} = useTranslation()

    // ** Context
    const [language] = useContext(LanguageContext)
    const [, , , , hiddenImage, , changeVisibilityImage] = useContext(ThemeContext)

    // ** Services
    const {error, getStatistic} = StatisticsService()

    // ** Breadcrumb
    const breadCrumb = [
        {
            title: t("resources"),
            link: null,
        },
        {
            title: t("statistics"),
            link: null,
        },
    ]

    // ** Effects
    useEffect(() => {
        getStatistic().then(res => {
            setData(res['data'])
            setDescription(res['description'])
            changeVisibilityImage()
        })
    }, []);

    useEffect(() => {
        if (hiddenImage) {
            changeVisibilityImage()
        }
    }, [])

    return (
        <section className='statistics page py mt-3'>
            {!error && data && description && <>
                <Container>
                    <BreadCrumb data={breadCrumb}/>
                    <h2 className='section__title' data-aos='fade-right' data-aos-delay='150'>{t("statistics")}</h2>
                    <Row>
                        {data.map((item, i) => (
                            <Col
                                key={item.id}
                                md={4}
                                sm={6}
                                className="mb-5"
                                data-aos='fade-up'
                                data-aos-delay={i < 6 ? (0 + ((i + 1) * 100)) : 0}
                            >
                                <div className='statistics__card'>
                                    <img src={item['image']} width="100%" height="320" alt={item['title'][language]}/>
                                    <h2>{item['amount']}</h2>
                                    <p>{item['title'][language]}</p>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
                <img src={lenta} width="100%" alt=""/>
                <Container>
                    <div dangerouslySetInnerHTML={{__html: description[0][language]}} data-aos='fade-up'/>
                </Container>
            </>}
        </section>
    )
}
