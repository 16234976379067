import {useContext, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import SettingsService from "../../services/SettingsService"
import FeedbackService from "../../services/FeedbackService"
import {LanguageContext} from "../../context/language/languageContext"
import * as Yup from "yup"

import {Col, Container, Row} from "react-bootstrap"
import {Form, Formik, useField} from "formik"
import {TextField} from "@mui/material"
import InputMask from "react-input-mask"
import AppModal from "../Modal/Modal"

import './AppForm.scss'

export const MyTextInput = ({label, ...props}) => {
    // ** States
    const [field, meta] = useField(props)

    return (
        <>
            <TextField
                {...props}
                {...field}
                label={label}
                variant="outlined"
                error={meta.touched && meta.error}
                fullWidth
            />
            {meta.touched && meta.error ? (
                <i className="error">{meta.error}</i>
            ) : null}
        </>
    )
}

function AppForm(props) {
    // ** States
    const [data, setData] = useState(null)
    const [open, setOpen] = useState(props.callback || false)

    // ** Context
    const [language] = useContext(LanguageContext)

    const {t} = useTranslation()
    const toggleOpen = () => setOpen(!open)

    // ** Services
    const {getSettings} = SettingsService()
    const {loading, sendFeedback} = FeedbackService()

    // ** Effects
    useEffect(() => {
        getSettings().then(res => {
            setData(res)
        })
    }, [language]);

    return (
        <section className='appform my-4 py mb-0 overflow-hidden' id={props.id}>
            <Container>
                <h2 className='section__title' data-aos='fade-up'>
                    {t("formTitle")}
                </h2>
                <Formik
                    initialValues={{
                        name: "",
                        number: "",
                        companyName: "",
                        email: "",
                        description: "",
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string()
                            .min(2, t("formMin") + " 2")
                            .required(t("formMust")),
                        companyName: Yup.string()
                            .min(2, t("formMin") + " 2")
                            .required(t("formMust")),
                        email: Yup.string()
                            .min(2, t("formMin") + " 2")
                            .required(t("formMust")),
                        number: Yup.string()
                            .min(19)
                            .required(t("formMust")),
                        description: Yup.string()
                    })}
                    onSubmit={(values, {resetForm}) => {
                        sendFeedback({
                            name: values['name'],
                            organization: values['companyName'],
                            phone: values['number'],
                            email: values['email'],
                            description: values['description']
                        }).then(() => {
                            toggleOpen()
                            resetForm()
                        })
                    }}
                >
                    {({values, resetForm, errors, handleChange, handleBlur}) => (
                        <Form>
                            <Row>
                                <Col md={6} className="mb-4" data-aos='fade-up'>
                                    <MyTextInput
                                        label={t("formName")}
                                        name="name"
                                        required
                                    />
                                </Col>
                                <Col md={6} className="mb-4" data-aos='fade-up'>
                                    <InputMask
                                        mask="+\9\9\8 \(99) 999-99-99"
                                        maskChar={null}
                                        value={values.number}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="number"
                                        error={errors.number}
                                        fullWidth
                                        required
                                    >
                                        {inputProps => (
                                            <TextField
                                                label={t("formTel")}
                                                {...inputProps}
                                            />
                                        )}
                                    </InputMask>
                                    {errors.number ? (
                                        <i className="error">{t("formMin")} 9</i>
                                    ) : null}
                                </Col>
                                <Col md={6} className="mb-4" data-aos='fade-up'>
                                    <MyTextInput
                                        label={t("formFirm")}
                                        name="companyName"
                                        required
                                    />
                                </Col>
                                <Col md={6} className="mb-4" data-aos='fade-up'>
                                    <MyTextInput
                                        label="E-mail"
                                        name="email"
                                        required
                                    />
                                </Col>
                                <Col xs={12} className="mb-4" data-aos='fade-up'>
                                    <MyTextInput
                                        label={t("formFirmAbout")}
                                        name="description"
                                        sx={{"label": {color: "#bf4597"}}}
                                        rows={5}
                                        multiline
                                    />
                                </Col>
                                {data ?
                                    <Col md={12} data-aos='fade-up'>
                                        * {t("formSubtitle1")}
                                        <a
                                            className="text-primary"
                                            href={data.file}
                                            target="_blank"
                                            rel='noreferrer'
                                        >
                                            {t("formSubtitleLink")}
                                        </a>
                                        {t("formSubtitle2")}
                                    </Col>
                                    : null}
                                <div className='d-flex justify-content-end mt-3' data-aos='fade-left'>
                                    <button
                                        type="submit"
                                        className={loading ? 'button button__purple button_disabled' : 'button button__purple'}
                                    >
                                        {t("formButton")}
                                    </button>
                                </div>
                                <AppModal
                                    type="successModal"
                                    handleCloseModal={toggleOpen}
                                    open={open}
                                />
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Container>
        </section>
    )
}

export default AppForm