import React, {useContext, useEffect, useState} from 'react'
import SettingsService from '../../services/SettingsService'

import TelegramIcon from '@mui/icons-material/Telegram'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import {LanguageContext} from '../../context/language/languageContext'
import {YouTube} from "@mui/icons-material"

import './Social.scss'

export default function Social() {
    // ** States
    const [data, setData] = useState(null)

    // ** Services
    const {getSettings} = SettingsService()

    // ** Context
    const [language] = useContext(LanguageContext)

    // ** Effects
    useEffect(() => {
        getSettings().then(res => {
            setData(res)
        })
    }, [language]);

    return (
        <>{data ?
            <div className="footer__social">
                {data['social']['telegram'] && (
                    <a href={data['social']['telegram']} target='_blank' rel='noreferrer'><TelegramIcon/></a>
                )}
                {data['social']['facebook'] && (
                    <a href={data['social']['facebook']} target='_blank' rel='noreferrer'><FacebookIcon/></a>
                )}
                {data['social']['instagram'] && (
                    <a href={data['social']['instagram']} target='_blank' rel='noreferrer'><InstagramIcon/></a>
                )}
                {data['social']['youtube'] && (
                    <a href={data['social']['youtube']} target='_blank' rel='noreferrer'><YouTube/></a>
                )}
            </div>
            : null}
        </>
    )
}
