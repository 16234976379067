import { useHttp } from "../hooks/http.hook";

export default function AboutPageService() {
    const { error, loading, request } = useHttp()

    const getAbout = async () => {
        return await request(`${process.env.REACT_APP_API}site/about-us`, "GET")
    }

    return { error, loading, getAbout }
}