import {useHttp} from "../hooks/http.hook"

export default function MainPageService() {
    const {error, loading, request} = useHttp()

    const getSlider = async () => {
        return await request(`${process.env.REACT_APP_API}site`)
    }

    const getBlock = async () => {
        return await request(`${process.env.REACT_APP_API}site`)
    }

    return {error, loading, getSlider, getBlock}
}