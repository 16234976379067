import {useContext, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {ThemeContext} from "../../context/theme/themeContext"
import AboutPageService from "../../services/AboutPageService"
import {LanguageContext} from '../../context/language/languageContext'

import {Container} from "react-bootstrap"
import PartnersSwiper from "../../components/PartnersSwiper/PartnersSwiper"
import {BreadCrumb} from "../../components/BreadCrumb/BreadCrumb"
import InformationSupportSwiper from "../../components/InformationSupportSwiper/InformationSupportSwiper"

import lenta from "../../resources/img/introabout/lenta2.png"
import './Project.scss'

export default function Project() {
    // ** States
    const [data, setData] = useState(null)
    const {t} = useTranslation()

    // ** Services
    const {error, getAbout} = AboutPageService()

    // ** Context
    const [language] = useContext(LanguageContext)
    const [, , , , hiddenImage, , changeVisibilityImage] = useContext(ThemeContext)

    // ** Breadcrumb
    const breadCrumb = [
        {
            title: t("project"),
            link: null,
        },
    ]

    // ** Effects
    useEffect(() => {
        getAbout().then(res => {
            setData(res[0])
            changeVisibilityImage()
        })

        if (hiddenImage) {
            changeVisibilityImage()
        }
    }, [])

    return (
        <>
            {!error && data &&
                <section className="project page mt-3 py">
                    <Container>
                        <BreadCrumb data={breadCrumb}/>
                        <h2 className='section__title' data-aos='fade-right' data-aos-delay='150'>{t("project")}</h2>
                        <div dangerouslySetInnerHTML={{__html: data[language]}} data-aos='fade-up'/>
                    </Container>
                    <img src={lenta} width="100%" alt="?"/>
                    <Container>
                        <div data-aos='fade-up'>
                            <PartnersSwiper smallPerView={2} mediumPerView={4} largePerView={6}/>
                        </div>
                        <div data-aos='fade-up'>
                            <InformationSupportSwiper smallPerView={2} mediumPerView={3} largePerView={5}/>
                        </div>
                    </Container>
                </section>
            }
        </>
    )
}