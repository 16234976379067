import { useHttp } from "../hooks/http.hook";

export default function OrganizationService() {
    const { error, loading, request } = useHttp()

    const getOrganization = async () => {
        return await request(`${process.env.REACT_APP_API}site/organization`, "GET")
    }

    return { error, loading, getOrganization }
}