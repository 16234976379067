import {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import HelpService from "../../services/HelpService"
import {ThemeContext} from '../../context/theme/themeContext'
import {LanguageContext} from "../../context/language/languageContext"
import {Link} from 'react-router-dom'

import {Container} from 'react-bootstrap'
import {BreadCrumb} from '../../components/BreadCrumb/BreadCrumb'

import lenta from '../../resources/img/introabout/lenta2.png'
import './Help.scss'

function Help() {
    // ** States
    const [data, setData] = useState(null)
    const {t} = useTranslation()

    // ** Services
    const {error, getHelp} = HelpService()

    // Context
    const [language] = useContext(LanguageContext)
    const [, , , , hiddenImage, , changeVisibilityImage] = useContext(ThemeContext)

    // ** Breadcrumb
    const breadCrumb = [
        {
            title: t("help"),
            link: null,
        },
    ]

    // ** Effects
    useEffect(() => {
        if (hiddenImage) {
            changeVisibilityImage()
        }

        getHelp().then(res => {
            setData(res[0])
            changeVisibilityImage()
        })
    }, [])

    return (
        <section className='help page mt-3'>
            {!error && data && (
                <Container>
                    <BreadCrumb data={breadCrumb}/>
                    <div className='d-flex justify-content-between flex-wrap align-items-center'>
                        <h2 className='section__title mb-0' data-aos='fade-right' data-aos-delay='150'>{t('help')}</h2>
                        <Link
                            to="/callback"
                            className='button button__purple w-auto d-flex align-items-center px-2 mt-2'
                            data-aos='fade-left'
                            data-aos-delay='150'
                        >
                            {t('help_btn')}
                        </Link>
                    </div>
                    <h2 className='help__title' data-aos='fade-up'>
                        {data['title'][language]}
                    </h2>

                    <div
                        className='help__content'
                        dangerouslySetInnerHTML={{__html: data['description'][language]}}
                        data-aos='fade-up'
                        data-aos-delay='150'
                    />
                </Container>
            )}
            <img src={lenta} width="100%" alt="?"/>
        </section>
    )
}

export default Help