import { useHttp } from "../hooks/http.hook";

export default function InformationSupportService() {
    const { error, loading, request } = useHttp()

    const getInfoSupport = async () => {
        return await request(`${process.env.REACT_APP_API}site/information-support`, "GET")
    }

    return { error, loading, getInfoSupport }
}