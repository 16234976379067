import {useState} from "react"
import {LanguageContext} from "./languageContext";

export default function LanguageState({children}) {
    const [language, setLanguage] = useState(localStorage.getItem('i18nextLng') || 'uz')

    return (
        <LanguageContext.Provider value={[language, setLanguage]}>
            {children}
        </LanguageContext.Provider>
    )
}