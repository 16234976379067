export class Language {
    constructor(languages) {
        this.languages = languages || [
            {
                title: "O'zbekcha",
                slug: 'uz'
            },
            {
                title: 'Русский',
                slug: 'ru'
            },
            {
                title: 'Қарақалпақ',
                slug: 'kar'
            }
        ]
    }

    getAvialableLanguages() {
        return this.languages
    }

    getCurrentLanguage() {
        return localStorage.getItem("i18nextLng") || "uz"
    }
}