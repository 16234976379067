import {useHttp} from "../hooks/http.hook";

export default function VideoService() {
    const {error, loading, request} = useHttp()

    const getVideo = async () => {
        return await request(`${process.env.REACT_APP_API}site/video-materials`, "GET")
    }

    return {error, loading, getVideo}
}