import {useContext, useEffect} from "react"
import {ThemeContext} from "../../context/theme/themeContext"

import IntroSection from "../sections/Intro/IntoSection"
import IntroAbout from "../sections/IntroAbout/IntroAbout"
import IntroMap from "../sections/IntroMap/IntroMap"
import IntroPartners from "../sections/IntroPartners/IntroPartners"

export default function MainPage(props) {
    // ** States
    const [, , , , hiddenImage, , changeVisibilityImage] = useContext(ThemeContext)

    // ** Effects
    useEffect(() => {
        if (hiddenImage) {
            changeVisibilityImage()
        }

        if (props.scrollTo) {
            setTimeout(() => {
                window.scroll({
                    top: document.querySelector(props.scrollTo).offsetTop - 70,
                    behavior: 'smooth'
                })
            }, 1000)
        }
    }, [])

    return <>
        <IntroSection/>
        <IntroAbout/>
        <IntroMap/>
        <IntroPartners/>
    </>
}