import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import './BreadCrumb.scss'

export function BreadCrumb({ data }) {
    const { t } = useTranslation()
    return (
        <div className='breadcrumb' data-aos='fade-right'>
            <Link to="/">{t("main")}</Link>
            {
                data.map(({ link, title }, i) => (
                    <Link key={i} to={link ? link : "/"} className={link ? "" : "pointer-none"}>{title}</Link>
                ))
            }
        </div>
    )
}
