import {useHttp} from "../hooks/http.hook"

export default function ArticlesService() {
    const {error, loading, request} = useHttp()

    const getArticles = async () => {
        return await request(`${process.env.REACT_APP_API}site/articles`, "GET")
    }

    return {error, loading, getArticles}
}