import React, {useContext, useEffect, useState} from 'react'
import ArticlesService from '../../services/ArticlesService'
import {useTranslation} from 'react-i18next'
import {LanguageContext} from '../../context/language/languageContext'
import {ThemeContext} from '../../context/theme/themeContext'

import {Col, Container, Row} from 'react-bootstrap'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import {BreadCrumb} from '../../components/BreadCrumb/BreadCrumb'

import './Article.scss'

export default function Article() {
    const [data, setData] = useState(null)
    const [visible, setVisible] = useState(6)
    const {t} = useTranslation()

    // ** Context
    const [language] = useContext(LanguageContext)
    const [, , , , hiddenImage, , changeVisibilityImage] = useContext(ThemeContext)

    // ** Services
    const {error, getArticles} = ArticlesService()

    const breadCrumb = [
        {
            title: t("resources"),
            link: null,
        },
        {
            title: t("article"),
            link: null,
        }
    ]

    // ** Format date
    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(date) {
        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('.');
    }

    // ** Show more data
    const showMore = () => {
        setVisible(visible + 3)

        if (hiddenImage) {
            setTimeout(() => changeVisibilityImage(), 10)
        }
    }

    // ** Effects
    useEffect(() => {
        getArticles().then(res => {
            setData(res.data)
            changeVisibilityImage()
        })
    }, [])

    return (
        <section className="article page py mt-3">
            {!error && data && (
                <Container>
                    <BreadCrumb data={breadCrumb}/>

                    <h2 className='section__title' data-aos='fade-right' data-aos-selay='150'>
                        {t("article")}
                    </h2>
                    <Row>
                        {data.slice(0, visible).map((item, i) => (
                            <Col
                                key={i}
                                lg={4}
                                sm={6}
                                className='mb-4'
                                data-aos='fade-up'
                                data-aos-delay={i < 6 ? (0 + ((i + 1) * 100)) : 0}
                            >
                                <div className='article__card'>
                                    <a
                                        href={item['link']}
                                        target="_blank"
                                        rel='noreferrer'
                                    >
                                        <img
                                            src={item['image']}
                                            width="400"
                                            height="350"
                                            alt={item['title'][language]}/>
                                    </a>
                                    <span className='ps-3'>Дата: {formatDate(new Date(item.date))}</span>
                                    <div className='p-3'>
                                        <h4>{
                                            item['title'][language].length > 70 ? (
                                                item['title'][language].slice(0, 70) + '...'
                                            ) : item['title'][language]
                                        }</h4>
                                        <p>{item['subtitle'][language]}</p>
                                    </div>
                                    <a className='article__card-read p-3 pt-0'
                                       href={item['link']}
                                       target="_blank"
                                       rel='noreferrer'
                                    >
                                        {t('readStory')} <ArrowRightAltIcon/>
                                    </a>
                                </div>
                            </Col>
                        ))}
                    </Row>
                    {data.length <= visible ? "" :
                        <button
                            className='button button__purple d-flex justify-content-center align-items-center mx-auto '
                            onClick={showMore}>Показать еще</button>
                    }
                </Container>
            )}
        </section>
    )
}