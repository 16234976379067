import {useContext, useEffect, useState} from 'react';
import HistoryService from '../../services/HistoryService';
import {ThemeContext} from '../../context/theme/themeContext';
import {LanguageContext} from '../../context/language/languageContext'

import {Col, Container, Row} from 'react-bootstrap'
import {BreadCrumb} from '../../components/BreadCrumb/BreadCrumb'
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import './Stories.scss'

export default function Stories() {
    // ** States
    const [data, setData] = useState(null)
    const [visible, setVisible] = useState(6)
    const [language] = useContext(LanguageContext)
    const {t} = useTranslation()
    const [, , , , hiddenImage, , changeVisibilityImage] = useContext(ThemeContext)

    // ** Services
    const {error, getHistory} = HistoryService()

    // Breadcrumb
    const breadCrumb = [
        {
            title: t("resources"),
            link: null,
        },
        {
            title: t("story"),
            link: null,
        }
    ]

    // ** Show more data
    const showMore = () => {
        setVisible(visible + 3)

        if (hiddenImage) {
            setTimeout(() => changeVisibilityImage(), 1)
        }
    }

    // ** Effects
    useEffect(() => {
        getHistory().then(res => {
            setData(res)
            changeVisibilityImage()
        })
    }, []);

    return (
        <section className="stories page py mt-3">
            {data && !error && (
                <Container>
                    <BreadCrumb data={breadCrumb}/>
                    <h2 className='section__title' data-aos='fade-right' data-aos-delay='150'>
                        {t("story")}
                    </h2>
                    <Row>
                        {data.slice(0, visible).map((item, i) => (
                            <Col
                                key={i}
                                lg={4}
                                sm={6}
                                className='mb-4'
                                data-aos='fade-up'
                                data-aos-delay={i < 6 ? (0 + ((i + 1) * 100)) : 0}
                            >
                                <div className='stories__card'>
                                    <Link to={"/story/" + item.id}>
                                        <img src={item.image} alt={item['title'][language]}/>
                                    </Link>
                                    <div className='p-3'>
                                        <h4>{
                                            item['title'][language].length > 70 ? (
                                                item['title'][language].slice(0, 70) + '...'
                                            ) : item['title'][language]
                                        }</h4>
                                        <p>{item['subtitle'][language]}</p>
                                    </div>
                                    <Link className='stories__card-read p-3 pt-0' to={"/story/" + item.id}>
                                        {t('read')} <ArrowRightAltIcon/>
                                    </Link>
                                </div>
                            </Col>
                        ))}
                    </Row>
                    {data.length <= visible ? "" :
                        <button className='button button__purple d-flex justify-content-center align-items-center mx-auto '
                                onClick={showMore}>Показать еще</button>
                    }
                </Container>
            )}
        </section>
    )
}