import React, {useState} from 'react'
import {useContext, useEffect} from "react"
import {useTranslation} from 'react-i18next'
import PartnersService from "../../services/PartnersService"
import {ThemeContext} from "../../context/theme/themeContext"
import {LanguageContext} from "../../context/language/languageContext"

import {Container} from 'react-bootstrap'
import AppForm from '../../components/AppForm/AppForm'
import {BreadCrumb} from '../../components/BreadCrumb/BreadCrumb'
import PartnersSwiper from '../../components/PartnersSwiper/PartnersSwiper'
import InformationSupportSwiper from "../../components/InformationSupportSwiper/InformationSupportSwiper"

import './Partners.scss'

export default function Partners() {
    // ** States
    const [data, setData] = useState(null)
    const {t} = useTranslation()

    // ** Services
    const {error, getPartnersDescription} = PartnersService()

    // ** Breadcrumb
    const breadCrumb = [
        {
            title: t("partners"),
            link: null,
        },
    ]

    // ** Context
    const [language] = useContext(LanguageContext)
    const [, , , , hiddenImage, , changeVisibilityImage] = useContext(ThemeContext)

    useEffect(() => {
        getPartnersDescription().then(res => setData(res['description']))

        if (hiddenImage) {
            changeVisibilityImage()
        }
    }, [])

    return (
        <section className='partners page mt-3'>
            {!error && data && <>
                <Container className='mb-5'>
                    <BreadCrumb data={breadCrumb}/>
                    <h2 className='section__title mb-2' data-aos='fade-right' data-aos-delay='150'>
                        {t('partnersProgram')}
                    </h2>
                    <div
                        className='mb-4'
                        dangerouslySetInnerHTML={{__html: data[language]}}
                    />
                    <PartnersSwiper smallPerView={2} mediumPerView={4} largePerView={6}/>
                    <InformationSupportSwiper smallPerView={2} mediumPerView={3} largePerView={5}/>
                </Container>
                <AppForm/>
            </>}
        </section>
    )
}
