import { useHttp } from "../hooks/http.hook";

export default function HistoryService() {
    const { error, loading, request } = useHttp()

    const getHistory = async () => {
        return await request(`${process.env.REACT_APP_API}site/history`, "GET")
    }

    const getHistoryId = async (id) => {
        return await request(`${process.env.REACT_APP_API}site/history/detail/${id}`, "GET")
    }

    return { error, loading, getHistory, getHistoryId }
}