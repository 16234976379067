import React, {useContext, useEffect, useState} from 'react'
import {ThemeContext} from "../../../context/theme/themeContext"
import {LanguageContext} from '../../../context/language/languageContext'
import MainPageService from '../../../services/MainPageService'

import {Col, Container, Row} from 'react-bootstrap'

import lenta1 from "../../../resources/img/introabout/lenta1.png"
import lenta2 from "../../../resources/img/introabout/lenta2.png"
import lenta3 from "../../../resources/img/introabout/lenta3.png"

import "./IntroAbout.scss"

function IntroAbout() {
    // ** STATES
    const [data, setData] = useState(null)

    // ** Services
    const {getBlock} = MainPageService()

    // ** Context
    const [language] = useContext(LanguageContext)

    // ** Context
    const [, , , , hiddenImage, , changeVisibilityImage] = useContext(ThemeContext)

    // ** Effects
    useEffect(() => {
        getBlock().then(res => {
            setData(res.mainBlocks)
        })
    }, []);

    useEffect(() => {
        if (hiddenImage) {
            changeVisibilityImage()
        }
    }, [data])

    return <>
        {data && (
            <section className='introabout overflow-hidden'>
                <Container>
                    <Row className="align-items-center justify-content-between">
                        <Col md={7} className="introabout__content">
                            <h2 className='section__title' data-aos='fade-right'>
                                {data[3]['title'][language]}
                            </h2>
                            <div dangerouslySetInnerHTML={{__html: data[3].description[language]}} data-aos='fade-up'/>
                        </Col>
                        <Col md={5} className="d-flex justify-content-center" data-aos='fade-left'>
                            <img
                                className='introabout__img'
                                src={data[3]['image']}
                                height="400"
                                width="400"
                                alt={data[3]['title'][language]}/>
                        </Col>
                    </Row>
                </Container>
                <img className='introabout__lenta1' src={lenta1} alt="lenta"/>
                <Container className='mt-3'>
                    <h2 className='section__title' data-aos='fade-right'>
                        {data[2]['title'][language]}
                    </h2>
                    <Row className="flex-column-md-reverse align-items-center justify-content-center mt-3">
                        <Col md={5} className="d-flex justify-content-center">
                            <img
                                className='introabout__img'
                                src={data[2]['image']}
                                height="400"
                                width="400"
                                alt={data[2]['title'][language]}
                                data-aos='fade-up'
                            />
                        </Col>
                        <Col md={7} className="introabout__content">
                            <div dangerouslySetInnerHTML={{__html: data[2].description[language]}} data-aos='fade-left'/>
                        </Col>
                    </Row>
                </Container>
                <img className='introabout__lenta' src={lenta2} alt="lenta"/>
                <Container className='mt-3'>
                    <Row className="align-items-center justify-content-center">
                        <Col md={7} className="introabout__content">
                            <h2 className='section__title' data-aos='fade-right'>
                                {data[1]['title'][language]}
                            </h2>
                            <div dangerouslySetInnerHTML={{__html: data[1].description[language]}} data-aos='fade-up'/>
                        </Col>
                        <Col md={5} className="d-flex justify-content-center">
                            <img
                                className='introabout__img'
                                src={data[1]['image']}
                                height="400"
                                width="400"
                                alt={data[1]['title'][language]}
                                data-aos='fade-left'
                            />
                        </Col>
                    </Row>
                </Container>
                <img className='introabout__lenta' src={lenta3} alt="lenta"/>
            </section>
        )}
    </>
}

export default IntroAbout