import {useEffect, useState} from "react"
import {ThemeContext} from "./themeContext";

export default function ThemeState({children}) {
    // ** States
    const [theme, setTheme] = useState(localStorage.theme)
    const [fontSize, setFontSize] = useState(16)
    const [hiddenImage, setHiddenImage] = useState(false)

    // ** Switch theme
    const switchTheme = () => {
        switch (theme) {
            case 'default':
                localStorage.setItem('theme', 'default')
                document.body.classList.remove('contrast')
                document.body.classList.remove('neon')
                document.body.classList.remove('dark')
                break
            case 'dark':
                localStorage.setItem('theme', 'dark')
                document.body.classList.remove('contrast')
                document.body.classList.remove('neon')
                document.body.classList.add('dark')
                break
            case 'contrast':
                localStorage.setItem('theme', 'contrast')
                document.body.classList.remove('dark')
                document.body.classList.remove('neon')
                document.body.classList.add('contrast')
                break
            case 'neon':
                localStorage.setItem('theme', 'neon')
                document.body.classList.remove('dark')
                document.body.classList.remove('contrast')
                document.body.classList.add('neon')
                break
            default:
                break
        }
    }

    // ** Image toggle
    const changeVisibilityImage = () => {
        const images = document.querySelectorAll('img')

        if (hiddenImage) {
            // ** Show
            images.forEach((item) => {
                const src = item.getAttribute('src')

                if (src !== "null") {
                    item.setAttribute('data-src', src)
                    item.setAttribute('src', null)
                }
            })
        } else {
            // ** Show
            images.forEach((item) => {
                const data_src = item.getAttribute('data-src')
                const src = item.getAttribute('src')

                if (src === "null") {
                    item.setAttribute('src', data_src)
                } else {
                    item.setAttribute('data-src', src)
                }
            })
        }
    }

    // ** Set defaults
    useEffect(() => {
        if (!localStorage.theme) {
            localStorage.setItem('theme', 'default')
        } else {
            switchTheme()
        }
    }, [])

    useEffect(() => {
        switchTheme()
    }, [theme])

    useEffect(() => {
        document.body.style.fontSize = `${fontSize}px`
    }, [fontSize])

    useEffect(() => {
        changeVisibilityImage()
    }, [hiddenImage])

    return (
        <ThemeContext.Provider value={[
            theme, setTheme,
            fontSize, setFontSize,
            hiddenImage, setHiddenImage, changeVisibilityImage
        ]}>
            {children}
        </ThemeContext.Provider>
    )
}