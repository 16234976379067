import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate
} from 'react-router-dom'
import LanguageState from "../context/language/LanguageState";
import ThemeState from "../context/theme/ThemeState";
import ScrollToTop from 'react-scroll-to-top'
import AOS from 'aos'

import ScrollTop from "../components/ScrollTop/ScrollTop"
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/_main.scss'
import '../styles/buttons.scss'
import '../styles/forms.scss'
import '../styles/various.scss'
import 'aos/dist/aos.css'

import Page404 from "../views/pages/404/404"
import Partners from "../views/pages/Partners"
import Help from "../views/pages/Help"
import Statistics from "../views/pages/Statistics"
import Videos from "../views/pages/Videos"
import Article from "../views/pages/Article"
import StoryName from "../views/pages/StoryName"
import Stories from "../views/pages/Stories"
import Project from "../views/pages/Project"
import MainPage from "../views/pages"
import {useEffect} from "react";

function App() {
    useEffect(() => {
        AOS.init({
            // offset: 100,
            duration: 1200
        })
        AOS.refresh()
    }, [])

    return (
        <Router>
            {/*<Suspense fallback={null}>*/}
            <ThemeState>
                <LanguageState>
                    {/* Header */}
                    <Header/>
                    <main>
                        <ScrollTop>
                            {/* Routes */}
                            <Routes>
                                <Route exact path="/" element={<MainPage/>}/>
                                <Route exact path="/callback" element={<MainPage scrollTo='#callback'/>}/>
                                <Route exact path="/project" element={<Project/>}/>
                                <Route exact path="/story" element={<Stories/>}/>
                                <Route exact path="/story/:id" element={<StoryName/>}/>
                                <Route exact path="/article" element={<Article/>}/>
                                <Route exact path="/video" element={<Videos/>}/>
                                <Route exact path="/statistics" element={<Statistics/>}/>
                                <Route exact path="/help" element={<Help/>}/>
                                <Route exact path="/partners" element={<Partners/>}/>
                                <Route path="*" element={<Page404/>}/>
                                <Route path="/index_ru.html" element={<Navigate to={'/'}/>}/>
                            </Routes>
                        </ScrollTop>
                    </main>
                    {/* Scroll top button */}
                    <ScrollToTop smooth top="400" color="#291258"/>
                    {/* Footer */}
                    <Footer/>
                </LanguageState>
            </ThemeState>
            {/*</Suspense>*/}
        </Router>
    );
}

export default App