import { useTranslation } from 'react-i18next'

const Translate = (title) => {
    const { t } = useTranslation()
    return t(title)
}

export default class Navigation {
    constructor(desktopNavigation, mobileNavigation) {
        this.desktopNavigation = desktopNavigation || [
            {
                link: '/project',
                title: Translate("project")
            },
            {
                title: Translate("resources"),
                children: [
                    {
                        link: '/story',
                        title: Translate("story")
                    },
                    {
                        link: '/article',
                        title: Translate("article")
                    },
                    {
                        link: '/statistics',
                        title: Translate("statistics")
                    },
                    {
                        link: '/video',
                        title: Translate("video")
                    }
                ]
            },
            {
                link: '/help',
                title: Translate("help")
            }, {
                link: '/partners',
                title: Translate("partners")
            },

        ];
        this.mobileNavigation = mobileNavigation || [
            {
                link: '/project',
                title: Translate("project")
            },
            {
                link: '/story',
                title: Translate("story")
            },
            {
                link: '/article',
                title: Translate("article")
            },
            {
                link: '/statistics',
                title: Translate("statistics")
            },
            {
                link: '/video',
                title: Translate("video")
            },
            {
                link: '/help',
                title: Translate("help")
            }, {
                link: '/partners',
                title: Translate("partners")
            },
        ];
    }

    // Get desktop navigation items
    getDesktopNavigationItems() {
        return this.desktopNavigation
    }

    // Get mobile navigation items
    getMobileNavigationItems() {
        return this.mobileNavigation
    }
}