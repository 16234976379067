import {useCallback, useContext, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link, NavLink} from 'react-router-dom'
import Navigation from '../../navigation/navigation'
import {Language} from '../../configs/language/language'
import {LanguageContext} from "../../context/language/languageContext"
import {ThemeContext} from "../../context/theme/themeContext"
import {t} from 'i18next'

import {Container, NavDropdown} from 'react-bootstrap'
import {Switch} from '@mui/material'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import AppModal from "../Modal/Modal"

import VisibilityIcon from '@mui/icons-material/Visibility'
import logo from './logo.png'
import './Header.scss'

const Header = () => {
    // ** States
    const [open, setOpen] = useState(false)
    const [modal, setModal] = useState(false)
    const {t, i18n} = useTranslation()
    const navigation = new Navigation()
    const lang = new Language()

    // ** Context
    const [language, setLanguage] = useContext(LanguageContext)

    // Change language
    const changeLang = useCallback(lang => {
        setLanguage(lang)
        i18n.changeLanguage(lang)
    }, [])

    // ** Toggle modal
    const toggleModal = () => setModal(!modal)

    // Dropdown
    const handleClick = useCallback(() => {
        setOpen(open => !open)

        document.body.classList.toggle("active")
    }, [])

    return (
        <View
            onChangeLang={changeLang}
            onOpen={handleClick}
            language={lang.getAvialableLanguages().find(item => item.slug === language).title}
            lang={lang}
            navigation={navigation}
            open={open}
            toggleModal={toggleModal}
            modal={modal}
            t={t}
        />
    )
}

const View = props => {
    const [checked, setChecked] = useState(true)
    const [
        , setTheme,
        fontSize, setFontSize,
        , setHiddenImage
    ] = useContext(ThemeContext)

    return (
        <header className="header">
            <Container>
                <div className="menu">
                    {/* Hamburger button */}
                    <div className={props.open ? "menu__hamburger active" : "menu__hamburger"} onClick={props.onOpen}>
                        <span/>
                        <span/>
                        <span/>
                        <span/>
                    </div>
                    {/* Logo */}
                    <Link to="/"
                          className="menu__brand"
                    >
                        <img
                            src={logo}
                            alt="logo"
                            height={39}/>
                        <span>
                            {t("logo")}
                        </span>
                    </Link>
                    {/* Desktop navbar */}
                    <ul className="menu__list">
                        {props.navigation.getDesktopNavigationItems()
                            .map((item, index) => (
                                <li className="menu__item" key={index}>
                                    {!item.children
                                        ? (
                                            <NavLink
                                                to={item.link}
                                                className={({isActive}) => isActive ? "active" : null}
                                            >
                                                {item.title}
                                            </NavLink>
                                        ) : (
                                            <span>
                                                <NavDropdown
                                                    id="nav-dropdown"
                                                    title={item.title}
                                                    renderMenuOnMount={true}
                                                    className="item"
                                                >
                                                    {item.children.map((item, i) => (
                                                        <NavLink
                                                            to={item.link}
                                                            className={({isActive}) => isActive ? "dropdown-item active" : "dropdown-item"}
                                                            key={i}
                                                        >
                                                            {item.title}
                                                        </NavLink>
                                                    ))}
                                                </NavDropdown>
                                            </span>
                                        )}
                                </li>
                            ))}
                    </ul>

                    <div>
                        {/* Theme Dropdown /*/}
                        <NavDropdown
                            className='context__dropdown'
                            title={<VisibilityIcon/>}
                        >
                            <h5>{props.t('xy1')}</h5>

                            <div className='font-color'>
                                <span onClick={() => setTheme('default')}>A</span>
                                <span onClick={() => setTheme('dark')}>A</span>
                                <span onClick={() => setTheme('contrast')}>A</span>
                                <span onClick={() => setTheme('neon')}>A</span>
                            </div>

                            <h5>{props.t('xy2')}</h5>

                            <div className='font-size'>
                                <span onClick={() => {
                                    if (fontSize !== 14) {
                                        setFontSize(size => size - 1)
                                    }
                                }}>A-</span>
                                <span onClick={() => setFontSize(16)}>A</span>
                                <span onClick={() => {
                                    if (fontSize < 25) {
                                        setFontSize(size => size + 1)
                                    }
                                }}>A+</span>
                            </div>

                            <h5>{props.t('xy3')}</h5>

                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={checked}/>}
                                    onChange={(e) => setChecked(e.target.checked)}
                                    onClick={() => setHiddenImage(hiddenImage => !hiddenImage)}/>
                            </FormGroup>

                            <button className='reset-button' onClick={props.toggleModal}>
                                {t('voice1')}
                            </button>
                            <button className='reset-button mt-2' onClick={() => {
                                setFontSize(16)
                                setTheme('default')
                                setHiddenImage(false)
                                setChecked(true)
                            }}>
                                {props.t('xy4')}
                            </button>
                        </NavDropdown>
                    </div>

                    <div className="menu__lang">
                        {/* Language Dropdown */}
                        <NavDropdown
                            id="nav-dropdown2"
                            title={props.language}
                            onSelect={(e) => props.onChangeLang(e)}
                            renderMenuOnMount={true}
                        >
                            {props.lang.getAvialableLanguages()
                                .map((item, i) => {
                                    return (
                                        props.language !== item.title
                                            ? (
                                                <NavDropdown.Item key={i} eventKey={item.slug}>
                                                    {item.title}
                                                </NavDropdown.Item>
                                            )
                                            : null
                                    )
                                })}
                        </NavDropdown>
                    </div>
                    {/* Mobile Menu */}
                    <div className={props.open ? "mob-menu active" : "mob-menu"}>
                        <div className="mob-menu__content">
                            <div className="mob-menu__header">
                                {/* Social */}
                                <div className="mob-menu__social">
                                    <Link to="/">
                                        <span onClick={props.onOpen}>{t("logo")}</span>
                                    </Link>
                                </div>
                            </div>
                            {/* Body */}
                            <div className="mob-menu__body">
                                <ul className="mob-menu__list">
                                    {props.navigation.getMobileNavigationItems()
                                        .map((item, index) => (
                                            <li className="mob-menu__item" key={index}>
                                                <NavLink
                                                    to={item.link}
                                                    onClick={props.onOpen}
                                                    className={({isActive}) => isActive ? "active" : null}> {item.title}
                                                </NavLink>
                                            </li>
                                        ))}
                                </ul>
                                {/* Language */}
                                <ul className="mob-menu__lang">
                                    {props.lang.getAvialableLanguages()
                                        .map((item, i) => {
                                            return (
                                                <li
                                                    key={i}
                                                    onClick={e => props.onChangeLang(e.currentTarget.textContent)}
                                                    className={props.language === item.title ? "active" : null}>{item.slug}</li>
                                            )
                                        })
                                    }
                                </ul>

                                <div className='mob-context'>
                                    <h5>Цвет сайта</h5>

                                    <div className='font-color'>
                                        <span onClick={() => setTheme('default')}>A</span>
                                        <span onClick={() => setTheme('dark')}>A</span>
                                        <span onClick={() => setTheme('contrast')}>A</span>
                                        <span onClick={() => setTheme('neon')}>A</span>
                                    </div>

                                    <h5>Размер шрифта</h5>

                                    <div className='font-size'>
                                        <span onClick={() => {
                                            if (fontSize !== 14) {
                                                setFontSize(size => size - 1)
                                            }
                                        }}>A-</span>
                                        <span onClick={() => setFontSize(16)}>A</span>
                                        <span onClick={() => {
                                            if (fontSize < 25) {
                                                setFontSize(size => size + 1)
                                            }
                                        }}>A+</span>
                                    </div>

                                    <h5>Изображения</h5>

                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Switch checked={checked}/>}
                                            onChange={(e) => setChecked(e.target.checked)}
                                            onClick={() => setHiddenImage(hiddenImage => !hiddenImage)}/>
                                    </FormGroup>

                                    <button className='reset-button' onClick={() => {
                                        setFontSize(16)
                                        setTheme('default')
                                        setHiddenImage(false)
                                        setChecked(true)
                                    }}>
                                        Сброс
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* Overlay */}
                        <div className="overlay" onClick={props.onOpen}/>
                    </div>
                </div>
            </Container>
            <AppModal type='voice' open={props.modal} handleCloseModal={props.toggleModal}/>
        </header>
    )
}

export default Header;