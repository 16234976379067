import {useHttp} from "../hooks/http.hook"

export default function FeedbackService() {
    const {loading, error, request} = useHttp()

    const sendFeedback = async (data) => {
        return await request(
            process.env.REACT_APP_API + 'site/feedback/send',
            'post',
            JSON.stringify(data)
        )
    }

    return {loading, error, sendFeedback}
}