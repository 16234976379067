import {useContext, useEffect, useState} from "react"
import {useLocation} from 'react-router-dom'
import OrganizationService from "../../services/OrganizationService"
import SettingsService from "../../services/SettingsService"
import {useTranslation} from "react-i18next"
import {ThemeContext} from "../../context/theme/themeContext"
import {LanguageContext} from "../../context/language/languageContext"

import {Link} from 'react-router-dom'
import {Col, Container, Row} from "react-bootstrap"
import Social from "../Social/Social"

import usoft from '../../resources/img/partners/usoft.svg'
import logo from '../Header/logo.png'
import proon from '../../resources/img/partners/UNDP.png'

import './Footer.scss'

const Footer = () => {
    // ** States
    const [data, setData] = useState(null)
    const [social, setSocial] = useState(null)
    const [language] = useContext(LanguageContext)
    const [, , , , hiddenImage, , changeVisibilityImage] = useContext(ThemeContext)
    const pathname = useLocation().pathname
    const location = pathname === "/"
    const {t} = useTranslation()

    // ** Services
    const {getSettings} = SettingsService()
    const {getOrganization} = OrganizationService()

    // ** Effects
    useEffect(() => {
        if (hiddenImage) {
            setTimeout(() => changeVisibilityImage(), 1)
        }
    }, [])

    useEffect(() => {
        getSettings().then(res => {
            setData(res)
        })
        getOrganization().then(res => {
            setSocial(res)
        })
    }, [language])

    return (
        <>
            {!location && data && social ?
                <footer className="footer">
                    <Container>
                        <Row className='justify-content-between'>
                            <Col md={6} sm={12} className="footer__logos mb-4">
                                <div className='footer__card'>
                                    <img src={logo} alt="Call-Umida logo"/>
                                    <span>{t('logo')}</span>
                                </div>
                                <div className='footer__card'>
                                    <img src={proon} alt="PROON"/>
                                    <span>{t('pron')}</span>
                                </div>
                            </Col>
                            <Col md={3} sm={6} className="mb-4">
                                <h4>{t('footer1')}</h4>
                                <div className="d-flex flex-column">
                                    <Link className="mt-4" to="/project">{t("project")}</Link>
                                    <Link className="mt-3" to="/partners">{t("partners")}</Link>
                                    <Link className="mt-3" to="/callback">{t('footer3')}</Link>
                                </div>
                            </Col>
                            <Col md={3} sm={6} className="mb-4">
                                <h4>{t('footer2')}</h4>
                                <Social/>
                                <h5>E-mail</h5>
                                <a href={`mailto:${data['email']}`}>{data['email']}</a>
                            </Col>
                            <Col xs={12} className="d-flex justify-content-md-end justify-content-center">
                                <h6>
                                    {t('developed_by')}
                                    <a href="http://usoft.uz" target='_blank' rel='noreferrer'>
                                        <img className="footer__last" src={usoft} width='auto' height="40" alt="USOFT"/>
                                    </a>
                                </h6>
                            </Col>
                        </Row>
                    </Container>
                </footer> : (
                    <a
                        className="d-flex justify-content-center text-primary mb-3"
                        target="_blank"
                        href={data && data['file']}
                        rel='noreferrer'
                    >
                        {t("politcs")}
                    </a>
                )}
        </>
    )
}

export default Footer