import {useHttp} from "../hooks/http.hook";

export default function PartnersService() {
    const {error, loading, request} = useHttp()

    const getPartner = async () => {
        return await request(`${process.env.REACT_APP_API}site/partners`, "GET")
    }

    const getPartnersDescription = async () => {
        return await request(`${process.env.REACT_APP_API}site/partners-program`, "GET")
    }

    return {error, loading, getPartner, getPartnersDescription}
}