import { useEffect } from "react"
import {useLocation} from "react-router-dom"

const ScrollTop = (props) => {
    const location = useLocation()

    useEffect(() => {
        window.scroll({top: 0, behavior: 'smooth'})
    }, [location])

    return <>{props.children}</>
};

export default ScrollTop