import {useState, useContext, useEffect} from 'react'
import InformationSupportService from "../../services/InformationSupportService";
import {useTranslation} from 'react-i18next'
import {Grid, Navigation} from "swiper"
import {ThemeContext} from "../../context/theme/themeContext"

import {Swiper, SwiperSlide} from "swiper/react"
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'

import "swiper/css"
import "swiper/css/grid"
import "swiper/css/navigation"
import "./PartnersSwiper.scss"

export default function InformationSupportSwiper(props) {
    // ** States
    const {t} = useTranslation()
    const [data, setData] = useState(null)

    // ** Services
    const {error, getInfoSupport} = InformationSupportService()

    // ** Context
    const [, , , , hiddenImage, , changeVisibilityImage] = useContext(ThemeContext)

    // ** Effects
    useEffect(() => {
        getInfoSupport().then(res => {
            setData(res)
            if (hiddenImage) {
                changeVisibilityImage()
            }
        })
    }, []);

    return (
        <>
            {!error && data && (
                <>
                    <div className='swiper-title'>
                        <h2 className='section__title mb-3 mt-md-5 mt-4'>{t("infoHelp")}</h2>
                        <div className='swiper_buttons'>
                            <div className="slidePrev-btn"><ArrowBackIosOutlinedIcon/></div>
                            <div className="slideNext-btn"><ArrowForwardIosOutlinedIcon/></div>
                        </div>
                    </div>

                    <Swiper
                        breakpoints={{
                            0: {
                                slidesPerView: props.smallPerView,
                                grid: {
                                    rows: 3,
                                    fill: 'row'
                                }
                            },
                            480: {
                                slidesPerView: props.mediumPerView,
                                grid: {
                                    rows: 3,
                                    fill: 'row'
                                }
                            },
                            1200: {
                                slidesPerView: props.largePerView,
                                grid: {
                                    rows: 3,
                                    fill: 'row'
                                }
                            },
                        }}
                        navigation={{
                            prevEl: '.slidePrev-btn',
                            nextEl: '.slideNext-btn'
                        }}
                        grid={{
                            rows: 3,
                            fill: 'row'
                        }}
                        spaceBetween={30}
                        modules={[Grid, Navigation]}
                        className="pSwiper"
                    >
                        {data.map((item) => (
                            <SwiperSlide key={item.id}>
                                <a href={item.link} rel='noreferrer' target='_blank'>
                                    <img src={item.image} alt={item.title}/>
                                </a>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </>
            )}
        </>
    )
}