import React from 'react'
import ReactDOM from 'react-dom'
import App from './app/App'
import './configs/language/i18n'

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
)