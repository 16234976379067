import {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import HistoryService from "../../services/HistoryService"
import {useParams} from "react-router-dom"
import {ThemeContext} from '../../context/theme/themeContext'
import {LanguageContext} from "../../context/language/languageContext"

import {Container} from 'react-bootstrap'
import {BreadCrumb} from '../../components/BreadCrumb/BreadCrumb'

import './StoryName.scss'

export default function StoryName() {
    // ** States
    const [data, setData] = useState()
    console.log(data)
    const {id} = useParams()
    const {t} = useTranslation()

    // ** Context
    const [language] = useContext(LanguageContext)
    const [, , , , hiddenImage, , changeVisibilityImage] = useContext(ThemeContext)

    // ** Services
    const {error, getHistoryId} = HistoryService()

    // ** Breadcrumb
    const breadCrumb = [
        {
            title: t("resources"),
            link: null,
        },
        {
            title: t("story"),
            link: "/story",
        },
        {
            title: data && data['title'][language],
            link: null,
        }
    ]

    // ** Effects
    useEffect(() => {
        if (hiddenImage) {
            changeVisibilityImage()
        }

        getHistoryId(id).then(res => {
            setData(res)
            changeVisibilityImage()
        })
    }, [])

    return (
        <section className='story-name page py mt-3'>
            {!error && data && (
                <Container>
                    <BreadCrumb data={breadCrumb}/>
                    <h2 className='section__title' data-aos='fade-right' data-aos-delay='150'>
                        {data['title'][language]}
                    </h2>
                    <div className='d-inline-block'>
                        <img
                            className='story-name__img border'
                            src={data['image']}
                            width="650"
                            height="400"
                            alt={data['title'][language]}
                            data-aos='fade-right'
                        />
                        <div dangerouslySetInnerHTML={{__html: data['description'][language]}} data-aos='fade-left'/>
                    </div>
                </Container>
            )}
        </section>
    )
}
