import {useState} from "react"
import axios from "axios"

export const useHttp = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    // ** Request
    const request = async (url, method = "get", data = null) => {
        setLoading(true)

        const response = await axios({
            mode: 'no-cors',
            url,
            method,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data
        }).then((response) => {
            return response.data
        }).catch((error) => {
            setLoading(false)
            setError(true)
            throw new Error(error)
        })
        setLoading(false)
        return response
    }

    // ** Set error status to false
    const clearError = () => setError(false)

    return {loading, error, request, clearError}
}