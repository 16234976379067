import React, {useContext, useEffect, useState} from "react"
import {Swiper, SwiperSlide} from "swiper/react"
import {LanguageContext} from "../../../context/language/languageContext"
import MainPageService from "../../../services/MainPageService"

import {Navigation, Pagination} from "swiper"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "./IntroSection.scss"

function IntroSection() {
    // ** States
    const [data, setData] = useState([])

    console.log(data)

    // ** Services
    const {getSlider} = MainPageService()

    // ** Context
    const [language] = useContext(LanguageContext)

    // ** Effects
    useEffect(() => {
        getSlider().then(res => setData(res['slider']))
    }, [])

    return (
        <>
            <section className="intro page">
                {data && data.length > 0 &&
                    <Swiper
                        pagination={true}
                        navigation={true}
                        modules={[Navigation, Pagination]}
                        className="mySwiper"
                        data-aos='fade-in'
                    >
                        {data.map(item => {
                            if (+item['active']) return (
                                <SwiperSlide key={item['id']}>
                                    <img
                                        src={item['image']}
                                        width="100%"
                                        height="500"
                                        alt={item['title'][language]}/>
                                </SwiperSlide>
                            )
                            else return null
                        })}
                    </Swiper>}
            </section>
        </>
    )
}

export default IntroSection